// CSS DO ARQUIVO LOGIN.JS //
.loginBackground{
    background-image: url('../login-background-dark.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    // border-radius: 100px 0px 0px 100px;
    width: 70%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1300px) {
      width: 60%;
    }
    @media only screen and (max-width: 1100px) {
      width: 55%;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .loginForm {
    width: 30%;
  height: 100vh;
  margin-right: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: margin-right 0.3s; /* Add a smooth transition effect */

  @media only screen and (max-width: 1300px) {
    margin-right: 60px;
    width: 40%;
  }
  @media only screen and (max-width: 1100px) {
    margin-right: 45px;
    width: 45%;
  }
  @media only screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
  }
  }

  .homepageBackground {
    background-image: url('../login-background-dark.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 59%; 
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    
  }
  .rowContainer{
    justify-self: center;
    height: 50%;
margin: 0px 11%;
bottom: 80px;
}

  .homepageCards{
    //top: 260px;
    @media only screen and (max-width: 5000px) {
      top: 430%;
    }
    @media only screen and (max-width: 2000px) {
      top: 300%;
    }
    @media only screen and (max-width: 1600px) {
      top: 200%;
    }
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;

    }

    .cardContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    
    .cardsImg {
      height: 80px;
      width: 80px;
      border-radius: 10px;
      margin-right: 10px;
    }
    
    .cardsTitle {
      color: #003566;
      font-size: 14px;
      font-weight: bold;
    }

    .cardsSubtitle{
      color: #003566;
      font-size: 12px;
      font-weight: 400;
    }
    
.returnButton{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  background-color: #003566;
  color: #fafafa;
  font-weight: normal;
  font-size: 1.3rem; /* Assuming '2xl' represents 2rem */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.iframeDiv{
   position: absolute;
  height: 160px;
//  height: calc(100% - 3rem);
  width: 100%;
  top: 3rem;
right: 0rem
}

.imgLogo{
height: 40%;
}

.textHomepage{
  padding-top: 20px;
  color: #f5f5f5;
  font-size: 14px;
}

.inputSearch{
  border-radius: 10px;
  margin-top: 15px;
  width: 700px;
  margin-bottom: 100px;
}